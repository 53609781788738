:root {
  --white: #f1f1f1;
  --black: #0e0e0e;
}

.Hotel-visualizer {
  color: var(--black);
  font-family: cursive;
  align-items: center;
  justify-content: center;
}

.Hotel-visualizer-bg {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  z-index: -1;
}

.Hotel-visualizer-content {
  margin: auto;
  width: 100vw;
}

.Hotel-visualizer-title {
  text-align: center;
  padding-top: 15vh;
}
.Hotel-visualizer-subtitle {
  text-align: center;
  padding-top: 3vh;
}
.Hotel-visualizer-excel-selector-dropdown-div {
  padding-top: 10vh;
  text-align: center;
  margin: auto;
}

.Hotel-visualizer-download-button-div {
  padding-top: 25vh;
  text-align: center;
  margin: auto;
}
